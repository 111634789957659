







import Vue from 'vue';
import Component from 'vue-class-component';
import CommonIcon from './CommonIcon.vue';

@Component({
  components: {
    CommonIcon,
  },
})
export default class CommonHandleLogin extends Vue {
  $parent!: any;

  mounted (): void {
    const { query } = this.$root.$route;
    const tokens = JSON.parse(query.tokens.toString());
    this.$root.$store.commit('HANDLE_LOGIN', { token: tokens.token, refreshToken: tokens.refresh_token });
    this.$root.$store.dispatch('getBaseData', false)
      .finally(() => {
        this.$parent.handleLoginRedirect();
      });
  }
}
