






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CommonIcon extends Vue {
  @Prop({ default: 'regular' })
  iconStyle!: 'regular' | 'solid' | 'light' | 'duotone' | 'brand' | string;

  @Prop(String)
  iconName!: string | null;

  get icon () {
    return () => import(`vue-svg-loader!./../assets/fa-icons/${this.iconStyle}/${this.iconName}.svg`);
  }
}
